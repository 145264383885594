
.App {
  font-family: 'Times New Roman', Times, serif;
}
 

 
@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100%{
    background-position: right;
  }
}

.cancelfloatingright{
  display: flex;
  padding: 1rem;
  flex-direction:row-reverse;  
}

 


.brand-logo{
  width: 30px;
  height: 30px;
  background-image: url('./MyAssets/transparebtbackground.png');
  background-position: center;
  background-size: cover;
}

.apointmentChoser{
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #676504 ;
  max-width: 600px;

}


.brand-logo-container{
  cursor: pointer;
  display: flex;
  align-items: center;
}


/*******         CALENDER APOINTMENT SERVICE CHOSEN HAND FOOT TREATMENT BOTTOM                                  *****************/

.handfoot-container{
  padding: 1rem 1rem;
}

.handfoot-treatment-grid-childs-selected{
  cursor: pointer;
  border-radius: 25px;
  margin: 1rem 1rem;
  padding: 2rem 3rem;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: #ffe67c;
  color: #295f2d;

}

.handfoot-treatment-grid-childs{
  cursor: pointer;
  border-radius: 25px;
  background-color: #f7a79a;
  margin: 1rem 1rem;
  padding: 2rem 3rem;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.handfoot-treatment-grid-childs:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}
.handfoot-treatment-grid-childs-selected:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}


/*******   SELECT A SERVICE *************************/

.product-title-heading{
  margin: 2rem 0rem;
  font-size: xx-large;
  font-weight: 700;
  text-align: center;

}
/*    Individual services  contaimer    */

.product-values-selected{
  cursor: pointer;
  margin : 2rem 1rem;
  padding: 2rem 2rem;
  border-radius: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: #ffe67c;
  color: #295f2d;

}
.product-values-selected:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.product-values{
  cursor: pointer;
  background: #f7a79a;
  margin : 2rem 1rem;
  padding: 2rem 2rem;
  border-radius: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.product-values:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.product-info{
color: #ee4e34;
font-size: 20px;
font-weight: 700;
text-align: center;

}
.body-treatments-product-info{
    color: #ee4e34;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    padding: 2rem 0rem;
}



.product-title{
  color: #676504;
  margin: 1.5rem 0rem;
  font-weight: 600;
  font-size: x-large;
  text-align: center;
}


.body-treatment-individual-box{
  margin: 1rem;
  border: 0.5px solid #Dcd0bc;
  padding: 1rem;
}

/*********** WHAT WE DO SERVICES ************************/


.cardServices{
  min-height: 500px;
  z-index: 5434;
  background: rgb(233,165,128);
  background: linear-gradient(73deg, rgba(233,165,128,1) 16%, rgba(237,189,192,1) 95%);
}

.cardServices h1{
  text-align: center;
  margin: 3rem 0rem;  
  color: #676504;
  font-weight: 700;
  letter-spacing: 14px;

}

.services-container{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: auto;
  color: rgb(254, 254, 254);

}
.right-service-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px,1fr));

  z-index: 12;
}

.right-service-container-box {
  color: white;
  text-align: center;
  background-color: #000000;
  margin: 3rem 1rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

}

.right-service-container-box:hover{
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}



 

.flex-c {
    padding-top: 5rem;
    display:flex;
    min-height: 500px;
    height:auto;
    color: black;
}
.container {
    top:40%;
    left:-12%;
    opacity:0.3;
    display:flex;
    height:100%;
    max-width: 100%;
    z-index: -2;
    margin-top: -300px;
    margin-left: -100px;
}


.clr-combo-container{
  display: flex;
  flex-direction: row;
}
.clr-cmbo{ 
  height: 15px;
  width: 100px;
  background-color: #Dcd0bc;
}







.container span {
  color: #c7c7c7;
  font-size:140px;
  z-index: -1;
  letter-spacing:20px;
  text-transform:uppercase;
}



.img4container{
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-area: 'picsframe';
}
.img4sly {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 400px 400px;

 }
.img4s{
  width: 100%;
  height: 400px;
  margin: 5px;
  background-color: #ffffff00;
  background-size: cover;

  background-position: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}


.responsive-height{
  height: 350px;
}

.responsibleP{
  min-height: fit-content;
}

@media (min-width: 576px) { 





  .container-b p {
    width: 450px;
  }

  .cardServicesContainer{
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    column-gap: 80px;
    row-gap: 50px;

  
  }


  .valueContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(400px, 1fr));
  }


  .handfoot-treatment-grid-parent{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(400px, 1fr));
  }
  
  
  
}

/* img4container  */





@media (min-width: 768px) { 
  .mainRaised {
    margin: "-60px 30px 0px";
    border-radius: "6px";
    box-shadow:0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }

.responsive-height{
  height: 400px;
}
  .img4s{
    width: 340px;
    height: 350px;
    margin: 5px;
    background-color: #ee4e34;
  }

 
  .container-b p {
    width: 450px;
  }

  
}


@media (min-width: 992px) { 

.responsive-height{
  height: 500px;
}
  .img4s{
    width: 400px;
    height: 400px;
    margin: 5px;
    background-color: #ee4e34;
  }

  .img4container{
    max-width: 1000px;
  }



 

  .container-b p {
    width: 450px;
    
  }

  .mygrid2{
    display: grid;
    grid-template-areas: 'picsframe picsframe  textformat'
    'picsframe picsframe  textformat'
    'picsframe picsframe  textformat' 
    'picsframe picsframe  textformat';
  }
  
  
}


@media (min-width: 1200px) { 

.responsive-height{
  height: 600px;
}

 

  .container-b p {
    width: 600px;
  }
  
  
}